@font-face {
  font-family: SFProdisplay;
  src: url('./font/SFProDisplay-Regular.ttf');
}
@font-face {
  font-family: sfbold;
  src: url('./font/SFProDisplay-Semibold.ttf');
}

$anft-blue: #007bff;

.theBackOfficeLayout {
  font-family: SFProdisplay !important;
  color: black;

  .card {
    box-shadow: none !important;
    margin-bottom: 1rem !important;
  }

  .c-sidebar-nav-title {
    font-weight: 100;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    // color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem !important;
  }

  .btn-primary {
    color: #fff;
    background-color: $anft-blue;
    border-color: $anft-blue;
    box-shadow: none;
  }

  .btn-outline-danger {
    color: #e55353;
    border-color: #e55353 !important;
    &:hover {
      color: #fff;
    }
  }

  .btn-outline-primary {
    color: $anft-blue;
    border-color: $anft-blue !important;
    &:hover {
      color: #fff;
      background-color: $anft-blue;
    }
  }

  .collapseIcon {
    color: rgba(0, 0, 0, 0.5);
    box-shadow: none !important;
    &:hover {
      color: black;
      box-shadow: none !important;
    }
  }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }

  .pagination {
    justify-content: center !important;

    .page-item.active {
      .page-link {
        color: #fff !important;
        background-color: $anft-blue !important;
        border-color: $anft-blue !important;
      }
    }
    .page-link {
      color: $anft-blue !important;
    }
  }

  .profileImageContainer {
    position: relative;
    .p-image {
      display: none;
      .upload-button {
        display: none;
      }
    }
  }

  .profileImageContainer:hover {
    .p-image {
      position: absolute;
      display: block;
      bottom: 30%;
      left: 38%;
      cursor: pointer;
      color: #ffffffb8;
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      .upload-button {
        font-size: 2em;
        display: block;
      }

      .upload-button:hover {
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        color: rgb(51, 163, 255);
      }
    }
    .p-image:hover {
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }

  .btn-link {
    font-weight: 400;
    color: $anft-blue;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    text-decoration: none;
  }

  .btn-link:hover {
    color: #0056b3;
    text-decoration: none;
    cursor: pointer;
  }
  .operationIcon {
    box-shadow: none !important;
    &:hover {
      color: black;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }
  h1,
  .h1 {
    font-size: 2.5rem;
  }

  h2,
  .h2 {
    font-size: 2rem;
  }

  h3,
  .h3 {
    font-size: 1.75rem;
  }

  h4,
  .h4 {
    font-size: 1.5rem;
  }

  h5,
  .h5 {
    font-size: 1.25rem;
  }

  h6,
  .h6 {
    font-size: 1rem;
  }

  .sfbold {
    font-family: sfbold;
  }
  .anftSideBar {
    background-image: linear-gradient(to bottom, #503be4 0%, #170d61 100%);

    .c-active {
      color: #fff !important;
      background: $anft-blue !important;
    }
  }

  .backOfficeHeader {
    .subHeader {
      background-color: #ebedef;
      border-bottom: none;
    }
    .headerIcon {
      color: rgba(0, 0, 0, 0.5);
      box-shadow: none !important;
      &:hover {
        color: black;
      }
    }
    .dropdown-item-title {
      font-size: 1rem;
      line-height: 1;
      margin: 0;
    }
    .dropdown-menu-lg {
      max-width: 300px;
      min-width: 280px;
      padding: 0;
    }
    .notifyDropdown {
      max-height: 80vh;
      min-width: 20vw;
      overflow-y: scroll;
    }
    .dropdown-header {
      font-size: 14px;
      font-weight: 400;
      color: #212529;
    }
    .dropdown-item {
      display: block;
      width: 100%;
      padding: 0.5rem 1rem;
      clear: both;
      font-weight: 400;
      color: #212529;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;

      &:hover,
      &:focus {
        color: #16181b;
        text-decoration: none;
        background-color: #f8f9fa;
      }
    }
    .dropdown-item.active,
    .dropdown-item:active {
      color: #321fdb !important;
    }
    .navbar-badge {
      font-size: 0.7rem;
      font-weight: 500;
      padding: 2px 4px;
      position: absolute;
      left: 19px;
      top: 16px;
    }
    .dropdown-divider {
      margin: 0;
    }
  }
  .backOfficeFooter {
    background-color: #fff;
    border-top: 1px solid #dee2e6;
    color: #869099;
  }
  .blue-anft-text {
    color: $anft-blue;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .font-weight-bold {
    letter-spacing: 0.2px;
  }
  .borderless td,
  .borderless th {
    border: none;
  }

  th,
  td {
    padding: 0.3rem;
  }

  .exchangeRateComponent {
    .customForm {
      margin-bottom: 0 !important;
      .titleCol {
        padding: 15px 0 15px 15px !important;
      }
      .valueCol {
        padding: 10px 15px 10px 0 !important;
      }
    }
    .plusCard {
      border: 1px solid #e5e5e5;
      .plusIcon {
        color: #e5e5e5;
      }
      &:hover {
        border: 1px solid #5a5a5a;
        cursor: pointer;
        .plusIcon {
          color: #5a5a5a;
          cursor: pointer;
        }
      }
    }
    .form-control:disabled,
    .form-control[readonly] {
      background-color: #3323a500;
      opacity: 1;
      color: #000;
      border: 0;
      font-size: 14px;
      padding-right: 0px;
    }
  }

  .circle-cms {
    border-radius: 1000px !important;
    overflow: hidden;
    width: 128px;
    height: 128px;
    border: 9px solid #2a1c8900;
    background-color: #d5d5d5;
  }

  .tabs-table-nav {
    .nav-link {
      color: #828282;
      &.active {
        border-bottom: 1px solid $primary;
        color: $primary;
      }
    }
  }

  .mobile-item-border {
    border-bottom: 1px solid #e7e7e7;
  }

  .content-title {
    font-size: 0.8rem;
    line-height: 19px;
  }
}

.cancel-button {
  color: #39f !important;
  border: 1px solid #39f !important;
  background: #fff;
  box-shadow: none !important;
}
.ql-editor {
  min-height: 150px;
}

.text-black {
  color: #181a1b !important;
}
.font-size-80 {
  font-size: 0.8rem;
}
.vh-100 {
  height: 100vh;
}

.custom-btn-primary {
  border: 1px solid !important;
  box-shadow: none !important;
  color: #007bff;
  border-color: #007bff !important;
  &:hover {
    color: #fff;
    background-color: #007bff;
  }
}