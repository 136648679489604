// Here you can add other styles

@font-face {
  font-family: SFProdisplay;
  src: url('./font/SFProDisplay-Regular.ttf');
}
@font-face {
  font-family: sfbold;
  src: url('./font/SFProDisplay-Semibold.ttf');
}

body {
  .theAuthLayout,
  .theFrontOfficeLayout {
    font-family: SFProdisplay !important;
    font-size: 1rem !important;

    .sfbold {
      font-family: sfbold;
    }
  }

  // .theBackOfficeLayout {
  //   font-family: SFProdisplay !important;
  // }

  /*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/

  .color-blue {
    color: #08bcf0 !important;
    text-decoration: none;
  }

  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.5);
  }

  #preloader:before {
    content: '';
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #5846f9;
    border-top-color: #e7e4fe;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
  }

  @-webkit-keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .mobile-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    .mobile-nav-child {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        color: #fff;
        padding: 10px 24px 10px 0;

        &:hover {
          background-color: #5846f9;
          cursor: pointer;
        }
      }

      .item-active {
        background-color: #007bff !important;
      }
    }
    li {
      color: #fff;
      padding: 10px 24px 10px 0;

      &:hover {
        background-color: #5846f9;
        cursor: pointer;
      }
    }
    .item-active {
      background-color: #007bff !important;
    }
  }

  .textInput, .btn-select-verify {
    box-shadow: none;
    font-size: 14px;
    color: #fff;
    border: 2px solid rgba(255, 255, 255, 0.25) !important;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .textInput:focus {
    box-shadow: none;
    font-size: 14px;
    color: #fff;
    border: 2px solid rgba(0, 130, 252, 0.8);
    background-color: rgba(0, 0, 0, 0.1);
  }

  .btn-custom {
    font-size: 12px;
    line-height: 46px;
    font-weight: 600;
    padding: 0 34px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    min-width: 140px;
    max-width: 100%;
    border: none;
    box-sizing: border-box;
    transition: all 0.5s;
    position: relative;
    z-index: 2;
    -ms-border-radius: 40px;
    border-radius: 6px;
  }

  .mt-100 {
    margin-top: 100px;
  }
  @media (max-width: 768px) {
    .mt-100 {
      margin-top: 40px;
    }
  }

  .mt-150 {
    margin-top: 150px;
  }
  @media (max-width: 768px) {
    .mt-150 {
      margin-top: 40px;
    }
  }

  .mt-150-dashboard {
    margin-top: 100px;
  }
  @media (max-width: 768px) {
    .mt-150-dashboard {
      margin-top: 40px;
    }
  }

  .frontOfficeHeader {
    .btn {
      font-size: 12px;
      line-height: 46px;
      font-weight: 600;
      padding: 0 34px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      min-width: 140px;
      max-width: 100%;
      border: none;
      box-sizing: border-box;
      transition: all 0.5s;
      position: relative;
      z-index: 2;
      -ms-border-radius: 40px;
      border-radius: 6px;
    }

    .dropdown-item {
      font-size: 14px !important;
      font-weight: 500 !important;
      text-transform: none !important;
      color: #2c4964 !important;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      font-size: 14px !important;
      font-weight: 500 !important;
      text-transform: none !important;
      color: #5846f9 !important;
      background-color: transparent !important;
    }

    .logoutButton {
      color: #fff;
      transition: 0.4s;
      border-radius: 5px;
      font-size: 14px;
      line-height: 40px;
      background-image: linear-gradient(to right, #7a5cff 0%, #a722f4 100%);
    }

    .logoutButton:hover {
      background-image: linear-gradient(to right, #7a5cff 100%, #a722f4 0%);
    }

    .language-btn {
      color: #fff;
      border-radius: 5px;
      transition: 0.3s;
      font-size: 14px;
      line-height: 36px;
      font-weight: 600;
      display: inline-block;
      border: 2px solid rgba(255, 255, 255, 0.5) !important;
    }

    .language-btn:hover {
      border-color: #fff;
      color: #fff;
    }

    @media (max-width: 992px) {
      .language-btn {
        padding: 6px 18px !important;
      }
      .logoutButton {
        padding: 6px 18px !important;
      }
    }
  }

  .particles-js {
    width: 100%;
    height: 100%;
    /* background-color: #b61924; */
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  .particles-js {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .circle {
    border-radius: 1000px !important;
    overflow: hidden;
    width: 128px;
    height: 128px;
    border: 9px solid #2a1c8900;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .profile-pic {
    max-width: 110px;
    max-height: 110px;
    display: block;
  }

  .profileImageContainer {
    position: relative;
    .p-image {
      display: none;
      .upload-button {
        display: none;
      }
    }
  }

  .profileImageContainer:hover {
    .p-image {
      position: absolute;
      display: block;
      bottom: 30%;
      left: 38%;
      cursor: pointer;
      color: #ffffffb8;
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      .upload-button {
        font-size: 2em;
        display: block;
      }

      .upload-button:hover {
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        color: rgb(51, 163, 255);
      }
    }
    .p-image:hover {
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }

  .file-upload {
    display: none;
  }

  .required-label:after {
    color: #e32;
    content: ' * ';
  }

  .bg_register {
    background-image: linear-gradient(to bottom, #3e2bc5 0%, #261986 100%);
  }

  .anft_card {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .textLabel {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 0.5rem;
  }

  .textLabel14px {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 0.5rem;
    font-size: 14px;
  }

  .formMarginNone {
    margin-bottom: 0;

    .social-links a {
      display: inline-block;
      color: #fff;
      line-height: 1;
      padding: 0 0;
      width: 18px;
      height: 18px;
      margin-right: 10px;
      border-radius: 50%;
      text-align: center;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .formBorderBlue {
    border: 1px solid rgba(1, 176, 241, 0.3);
    padding: 15px 5px;
  }

  .footer {
    color: #fff !important;
    font-size: 14px !important;
    z-index: 2;
    background: linear-gradient(45deg, rgba(86, 58, 250, 0.9) 0%, rgba(116, 15, 214, 0.9) 100%),
      url('../assets/img/hero-bg.jpg') center center no-repeat !important;
    border: none;
    background-size: cover !important;
    .social-links a {
      font-size: 18px;
      display: inline-block;
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
      line-height: 1;
      padding: 8px 0;
      margin-right: 4px;
      border-radius: 50%;
      text-align: center;
      width: 36px;
      height: 36px;
      transition: 0.3s;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        color: #fff;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(47, 61, 141);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #517fff;
    border-radius: 5px;
  }
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: rgb(47, 61, 141);
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background: #517fff;
  border-radius: 5px;
}
.cursor-pointer {
  cursor: pointer;
}
.info-icon {
  font-size: 1.2em;
  color: #fff;
}
.btnCheckAuth {
  min-width: initial !important;
  line-height: normal !important;
  &:disabled {
    opacity: 1 !important;
  }
}

.btn-select-verify:hover {
  color: #08bcf0;
  border: 2px solid #08bcf0 !important;
}